import { StaticImage as Img } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import serviceData from "~data/marketing/EmailService";
import ServiceWidget from "./Component/Widget";
import Service from "./style";
import AccordionDisplay from "~sections/pricing/Faq/components/FaqWidget"

export default function ServiceSection() {
  return (
    <>
      <Service className=" border-default-color-2 bg-default">
        
        <Container>
            <Service.Box mtLG="100px" mtMD="40px" mbLG="100px" mbMD="40px">
              <Row className="justify-content-center justify-content-md-start">
                {/* Single Service */}
                {serviceData.services.map(({ title, icon, text,id }) => {
                  return(<Col className="col-lg-4 col-xs-6 col-10" key={id}>
                  <ServiceWidget icon={icon} title={title} text={text}  id={id} mt="40px" /></Col>) 
                })}
              </Row>
            </Service.Box>
            
            
            <Row className="justify-content-center" >
          <Col className="col-xl-6 col-lg-8 col-md-9 col-xs-10 text-center">
              <Service.Box mb="58px" mtLG="100px" mtMD="40px">
              <Service.Title as="h1">Frequently Asked Question</Service.Title>
              </Service.Box>
          </Col>
        </Row>
        {/* Faqs */}
        <section className="faq-sec">
            <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
              
              <div className="my-3">
              <AccordionDisplay 
              title="What is an email signature & why do you need one?"
              arrowPosition="right"
              forceDefaultHide={true}
              >
              <p>An email signature is a unique block of content that you can add to the end of your emails. Typically, a signature contains your name, contact information, company information, job title, and a call to action. <br/>Your email signature is an essential part of company branding, and it can boost your credibility when reaching out to prospects or customers.</p>
              </AccordionDisplay>
              </div>
              <div className="my-3">
              <AccordionDisplay 
              title="Why should you use an email signature generator?"
              arrowPosition="right"
              forceDefaultHide={true}
              >
              <p>You can create a simple email signature in minutes using an email signature generator. Our tool enables you to choose from different design templates while easily importing your information. An email signature generator is compatible with major email providers like Gmail, Outlook, Yahoo Mail, and Apple Mail.</p>
              </AccordionDisplay>
              </div>
              <div className="my-3">
              <AccordionDisplay 
              title="How do you add an email signature to Gmail?"
              arrowPosition="right"
              forceDefaultHide={true}
              >
              <p>To add an email signature to your Gmail account, click the Gear icon and click “See all settings.” Once you’ve arrived at the settings page, scroll down until you find the signature section. Select “Create new” and paste or add your signature to the text box. Below the text box choose when you’d like your email signature to appear. Finally, save the changes to your settings at the bottom of the page.</p>
              </AccordionDisplay>
              </div>
              <div className="my-3">
              <AccordionDisplay 
              title="How do you add an email signature to Outlook?"
              arrowPosition="right"
              forceDefaultHide={true}
              >
              <p>Click on the Gear icon in the top right corner of your inbox to open your Outlook settings. Head to “Compose and reply,” select, “New signature,” and paste/add your signature in the text box below. <br/>Change the individual settings of your signature below and save your settings.</p>
              </AccordionDisplay>
              </div>
              <div className="my-3">
              <AccordionDisplay 
              title="How do you make a signature mobile-friendly?"
              arrowPosition="right"
              forceDefaultHide={true}
              >
              <p>More than half of all emails are opened on a mobile device. To keep your signature optimized for any type of screen, here's what we recommend: </p>
                  <p className="px-4 py-3 focus:outline-none focus-visible:ri">• Keep it simple and easy to read on a small screen.<br/>• Ensure your signature is no wider than 600 pixels.<br/>• Incorporate icons to save space and enhance your signature's visual appeal.<br/>• Use a signature generator that's optimized for mobile devices (like our free tool)!</p>
              </AccordionDisplay>
              </div>
              </div>
            
          </section>
          {/* Faqs */}
        </Container>
      </Service>
    </>
  )
}
//<br className="d-none d-xs-block" /> 
                    //<br className="d-none d-sm-block" />