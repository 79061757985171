import Images from "~data/imageImports";
const serviceData = {
    services: [
        {
          id:"ms1",
          icon: Images.emailSignature.customizeWithVariables,
          title: "Customize with variables.",
          text:
            "Add social links, call-to-action buttons, and your contact information.",
        },
        {
          id:"ms2",
          icon: Images.emailSignature.ReinforceYourCompanyBranding,
          
          title: "Reinforce your company branding",
          text:
            "Pick the signature template that’s best fit for your industry.",
        },
        {
          id:"ms3",
          icon: Images.emailSignature.addPersonalTouch,
          
          title: "Add a personal touch.",
          text:
            "Upload an image of your choice to your signature for added credibility.",
        },
      ]
  }
  export default serviceData;