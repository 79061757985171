import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import { Helmet } from "react-helmet"
import HeroSection from "~sections/project/EmailSign/Hero/Hero"
import Service from "~sections/project/EmailSign/Service/ServiceSection"

const header = {
    headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
    containerFluid:false,
    buttonBlock: (
      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex"
        btnOneText="Login"
        btnTwoText="14 day free trial"
        mr="15px"
        mrLG="0"
      />
    ),
  }

  export default function EmailSignature() {
    return (
      <>
      <Helmet>
        <title>Email Signature Generator</title>
        <meta name="description" content="Personalize your very own email signature using We-Connect’s free tool."/>
        <meta property="og:title" content="We-Connect | Email Signature Generator"/>
        <meta property="og:description" content="Personalize your very own email signature using We-Connect’s free tool."/>
        <meta property="og:image" content="https://we-connect.io/we-connect-free-email-signature-generator.png"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://we-connect.io/free-email-signature-generator"/>
        <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
        <meta property="twitter:title" content="We-Connect | Email Signature Generator"/>
        <meta property="twitter:description" content="Personalize your very own email signature using We-Connect’s free tool."/>
        <meta property="twitter:image" content="https://we-connect.io/we-connect-free-email-signature-generator.png"/>
    </Helmet>
      <PageWrapper headerConfig={header}>
        <HeroSection />
        <Service />
        <CtaSection />
        <FooterOne />
      </PageWrapper>
      </>
    )
  }