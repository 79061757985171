import styled from 'styled-components/macro';
import { Box, Heading,Button,Paragraph } from '~styled';
import { Link } from '~components';

const Hero = styled(Box)`
    padding-top: 100px;
    padding-bottom: 60px;
    position: relative;

    @media (min-width: 768px){
        padding-top: 140px;
        padding-bottom: 60px;
    }

    @media (min-width: 992px){
        padding-top: 150px;
        padding-bottom: 60px;
    }

    @media (min-width: 1200px){
        padding-top: 170px;
        padding-bottom: 60px;
    }

    @media (min-width: 1366px){
        padding-top: 160px;
        padding-bottom: 160px;
    }

`

Hero.Title = styled(Heading)`
font-weight: 500;
letter-spacing: -2px;
line-height: 1.1;
font-size: 40px;
margin-bottom: 25px;
z-index: 1;
position: relative;

@media (min-width: 480px) {
    font-size: 40px;
}

@media (min-width: 768px){
    font-size: 50px;
}

@media (min-width: 992px){
    font-size: 51px;
}
@media (min-width: 1200px){
    font-size: 54px;
}
@media (min-width: 1400px){
    font-size: 60px;
}
`
Hero.TypeEffect = styled(Heading)`
font-weight: 500;
letter-spacing: -2px;
line-height: 1.1;
font-size: 30px;
margin-bottom: 25px;
z-index: 1;
position: relative;

@media (min-width: 480px) {
    font-size: 30px;
}

@media (min-width: 768px){
    font-size: 40px;
}

@media (min-width: 1200px){
    font-size: 40px;
}
`
Hero.TitleShape = styled(Box)`
position: absolute;
left: 0;
top: 0;
z-index: 0;
width: 18.5%;

@media (min-width: 768px){
    left: 5%;
    top: -5%;
}

@media (min-width: 992px){
    left: 6%;
    top: -22%;
}
`

Hero.Text = styled(Paragraph)`
color: var(--color-texts-3);
font-size: 18px;
font-weight: 400;
letter-spacing: normal;
line-height: 34px;

@media (min-width: 768px){
    font-size: 21px;
}
`

Hero.ContentBlock = styled(Box)`
    
`

Hero.Button = styled(Link)`
border-radius: 500px;
padding: 16px 35px;
margin-top:10px;
min-width: 137px;
`
Hero.Image = styled(Box)`
    width: auto;
    margin-bottom: 20px;
    @media (min-width: 992px){
        width: 420px;
        margin-left: 140px;
    }
    @media (min-width: 1182px){
        width: 500px;
        margin-left: 140px;
    }
    @media (min-width: 1382px){
        width: 610px;
        margin-left: 140px;
    }
    

`
Hero.ImageShape01 = styled(Box)`
position: absolute;
right: -3.5%;
bottom: 35%;
z-index: 0;
width: 9.7%;
`
Hero.ImageShape02 = styled(Box)`
position: absolute;
left: -4%;
bottom: 20%;
z-index: 0;
width: 10%;
`
Hero.Box = styled(Box)`

`
export default Hero;
